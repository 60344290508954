<template>
  <v-container align="center" fluid class="d-flex justify-center mb-15">
    <v-card :loading="loading" min-width="1000">
      <!-- <v-row>
        <v-col cols="10"></v-col>
        <v-specer></v-specer>
        <v-col cols="2">
          <v-btn text @click="print">
            <v-icon class="pr-5" large color="black">mdi-printer </v-icon>
          </v-btn>
        </v-col>
      </v-row> -->

      <v-card-title class="ml-10 mt-5"> </v-card-title>

      <v-card-text>
        <v-row class="ml-10">
          <v-col cols="9" class="mt-7">
            <v-text style="font-size: 5rem" class="amber--text text--darken-4">
              INVOICE
            </v-text>
          </v-col>
          <v-col cols="2">
            <v-img height="100%" :src="companyinfo.logo_url"></v-img>
          </v-col>
        </v-row>
        <div id="dataapp">
          <v-row class="ml-10 mt-10">
            <v-col cols="3">
              <p class="font-weight-medium">Invoice Number:</p>
              <p>{{ invoiceinfo.id }}</p>
            </v-col>
            <v-col cols="3">
              <p class="font-weight-medium">Payment status:</p>
              <p>{{ orderinfo.order_status }}</p>
            </v-col>

            <v-col cols="3">
              <p class="font-weight-medium">Date of Issue:</p>
              <p>{{ invoiceinfo.date }}</p>
            </v-col>

            <v-col cols="3">
              <p class="font-weight-medium">Time:</p>
              <p>{{ invoiceinfo.time }}</p>
            </v-col>
          </v-row>

          <v-row class="ml-10 pa-0 mt-5">
            <v-col cols="3" class="py-0">
              <p class="font-weight-medium">Customer Name</p>
            </v-col>
            <v-col cols="5" class="py-0">
              <p>: {{ bill.name }}</p>
            </v-col>
            <v-col cols="2" class="py-0">
              <p>Ref :</p>
            </v-col>

            <v-col cols="2" class="py-0">
              <p>{{ invoiceinfo.ref_invoice }}</p>
            </v-col>

            <v-col cols="3" class="py-0">
              <p class="font-weight-medium">Billing Address</p>
            </v-col>
            <v-col cols="9" class="py-0">
              <p>: {{ bill.address }}</p>
            </v-col>
            <v-col cols="3" class="py-0">
              <p class="font-weight-medium">City</p>
            </v-col>
            <v-col cols="9" class="py-0">
              <p>: {{ bill.location }}</p>
            </v-col>
            <v-col cols="3" class="py-0">
              <p class="font-weight-medium">Phone Number</p>
            </v-col>
            <v-col cols="9" class="py-0">
              <p>: {{ bill.phone_number }}</p>
            </v-col>

            <!-- <v-col cols="3" class="mb-0">
            <p>Billed To:</p>
          </v-col>
          <v-col cols="9">
            <p>company name</p>
          </v-col> -->
            <!-- <v-col cols="3">
              <p class="font-weight-medium">Client Name</p>
            </v-col>
            <v-col cols="5">
              <p>: {{ bill.name }}</p>
            </v-col>
            <v-col cols="2">
              <p>Reference:</p>
            </v-col>
            <v-col cols="2">
              <p>{{ invoiceinfo.ref_invoice }}</p>
            </v-col>
            <v-col cols="3" style="padding-top: 0">
              <p class="font-weight-medium">Billing Address</p>
            </v-col>
            <v-col cols="9" style="padding-top: 0">
              <p>: {{ bill.address }}</p>
            </v-col>
            <v-col cols="3" style="padding-top: 0">
              <p class="font-weight-medium">City</p>
            </v-col>
            <v-col cols="9" style="padding-top: 0">
              <p>: {{ bill.location }}</p>
            </v-col>
            <v-col cols="3" style="padding-top: 0">
              <p class="font-weight-medium">Phone Number</p>
            </v-col>
            <v-col cols="9" style="padding-top: 0">
              <p>: {{ bill.phone_number }}</p>
            </v-col> -->
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                :headers="headers"
                :items="orders"
                class="elevation-0"
                hide-default-footer
              >
              </v-data-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="8"> </v-col>

            <v-col cols="4">
              <v-col class="d-flex flex-row">
                <p class="font-weight-medium">Sub Total:</p>
                <v-spacer></v-spacer>
                <p class="mr-5">{{ orderinfo.sub_price }}</p>
              </v-col>

              <v-col class="d-flex flex-row" style="padding-top: 0">
                <p class="font-weight-medium">Discount:</p>
                <v-spacer></v-spacer>
                <p class="mr-5">{{ orderinfo.overall_discount }}</p>
              </v-col>
              <v-col class="d-flex flex-row" style="padding-top: 0">
                <p class="font-weight-medium">VAT:</p>
                <v-spacer></v-spacer>
                <p class="mr-5">0</p>
              </v-col>
              <v-col class="d-flex flex-row" style="padding-top: 0">
                <p class="font-weight-medium">Grand Total:</p>
                <v-spacer></v-spacer>
                <p class="mr-5">{{ orderinfo.price_total }}</p>
              </v-col>
            </v-col>
          </v-row>

          <v-row class="ml-10 d-flex flex-row">
            <v-col cols="12">
              <p class="font-weight-medium">
              
              </p>
              <p class="text-caption">
                This Invoice Is Autogenareted By {{ companyinfo.name }} After
                Confirming Your Order And Payment
              </p>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>
       


<script>
export default {
  data: () => ({
    invoiceinfo: [],
    orders: [],
    orderinfo: [],
    bill: [],
    specifications: [],
    companyinfo: [],
    snackbar: "",
    invoicearray: [],
    headers: [
      {
        text: "Quantity",
        align: "start",
        sortable: false,
        value: "quantity",
        class: "amber darken-4 text-button white--text",
      },
      {
        text: "Unit",
        value: "unit",
        sortable: false,
        class: "amber darken-4 text-button white--text",
      },
      {
        text: "Short Description",
        value: "product_name",
        sortable: false,
        class: "amber darken-4 text-button white--text",
      },
      {
        text: "Unit Price ",
        value: "unit_price",
        sortable: false,
        class: "amber darken-4 text-button white--text",
      },
      {
        text: "Amount",
        value: "total_price",
        sortable: false,
        class: "amber darken-4 text-button white--text",
      },
    ],
  }),
  methods: {
    initialize(item) {
      axios
        .get(`Cart/create_invoice/${this.$route.query.id}/`)
        .then((response) => {
          // console.log("this is all data", response.data)
          this.invoiceinfo = response.data.invoice_data;
          this.orderinfo = response.data.order_data;
          this.bill = response.data.billing_address_data;
          this.orders = response.data.order_data.orders;
          this.totalRemaining = this.orders.reduce(
            (previous, current) => previous + current.remaining,
            0
          );

          // console.log("table info", response.data);
          this.companyinfo = response.data.company_data;
        });
    },

    // print(dataapp) {
    //   var divToPrint = document.getElementById("dataapp");

    //   var style = "<style>";
    //   style = style + "table {width: 100%;font: 17px Calibri;}";
    //   style =
    //     style +
    //     "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
    //   style = style + "padding: 2px 3px;text-align: center;}";
    //   style = style + "</style>";

    //   var win = window.open("", "", "height=1900,width=1900");
    //   win.document.write(style); //  add the style.
    //   win.document.write(divToPrint.outerHTML);
    //   win.document.close();
    //   win.print();
    // },
  },
  mounted() {
    this.initialize();
  },
};
</script>


<style scoped>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.2rem;
  height: 48px;
  background-color: #ff7743;
  color: white !important;
}

.demo {
  background-color: #ff7743;
}

/* .invoice{
  background: #f5f5f5; 
  font-size: 10em
}
.invoice .theme--light.v-table{
  background-color: #00f !important;
} */
</style>